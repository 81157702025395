<div [ngClass]="{ row: layout === 'inline' }">
  <label *ngIf="label" [attr.for]="inputId" class="form-label" [ngClass]="{ 'col-form-label col-sm-2 text-sm-right': layout === 'inline' }">
    {{ label }}
    <i
      *ngIf="showLabelInfo"
      [ngbPopover]="labelInfoTemplate"
      triggers="mouseenter:mouseleave"
      style="margin-bottom: 10px"
      class="far fa-circle-info tiny-tooltip"
      popoverClass="dark-popover"
    ></i>
  </label>
  <div [ngClass]="{ 'col-sm-10': layout === 'inline' }" class="inputWrapper">
    <div
      [ngClass]="{
        'has-clear': clearable,
        'input-group': prefix || suffix,
        'input-group-sm': (prefix || suffix) && this.size === 'small',
        'input-group-lg': (prefix || suffix) && this.size === 'large',
      }"
    >
      <span *ngIf="prefix" class="input-group-text prefix">{{ prefix }}</span>
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'date'" [ngTemplateOutlet]="dateTemplate"></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container>
      </ng-container>
      <span *ngIf="clearable && value && !isDisabled && !readonly" class="form-control-clear" (click)="clearAndEmit()">×</span>
      <span *ngIf="suffix" class="input-group-text suffix">{{ suffix }}</span>
    </div>
  </div>
</div>

<ng-template #dateTemplate>
  <input
    #inputFieldToFocus
    type="text"
    class="form-control"
    [ngClass]="{
      disabled: isDisabled,
      'form-control-sm': this.size === 'small',
      'form-control-lg': this.size === 'large',
      'has-value': value !== null && value !== undefined && value !== '',
    }"
    [attr.id]="inputId"
    [attr.name]="name"
    [attr.placeholder]="placeholder"
    [disabled]="isDisabled"
    [readonly]="readonly"
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="datePicker"
    (click)="datePicker.open()"
    (focus)="onFocus()"
    [ngModel]="dateValue()"
    (ngModelChange)="emitChange($event)"
    (blur)="onBlur()"
    [ngbPopover]="inputInfoText"
    triggers="mouseenter:mouseleave"
  />
  <mat-datepicker [disabled]="isDisabled || readonly" #datePicker></mat-datepicker>
</ng-template>

<ng-template #defaultTemplate>
  <input
    #inputFieldToFocus
    [type]="type"
    class="form-control"
    [ngClass]="{
      disabled: isDisabled,
      'text-end': type === 'number',
      'form-control-sm': this.size === 'small',
      'form-control-lg': this.size === 'large',
      'has-value': value !== null && value !== undefined && value !== '',
    }"
    [attr.id]="inputId"
    [attr.name]="name"
    [attr.min]="min"
    [attr.max]="max"
    [attr.step]="stepSize"
    [attr.maxlength]="maxLength"
    [attr.minlength]="minLength"
    [attr.pattern]="pattern"
    [attr.placeholder]="placeholder"
    [attr.accept]="acceptFileTypes"
    [attr.multiple]="multipleFiles"
    [attr.autocomplete]="autocomplete"
    [disabled]="isDisabled"
    [readonly]="readonly"
    [ngModel]="value"
    (ngModelChange)="type === 'file' ? undefined : emitChange($event)"
    (change)="type === 'file' ? fileChange($event) : undefined"
    (focus)="focused.next()"
    (blur)="onBlur()"
    [attr.data-cy]="'input'"
    [ngbPopover]="inputInfoText"
    triggers="mouseenter:mouseleave"
  />
</ng-template>
