import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '@services/url.service';
import { SelectListItem } from '@shared/models/select-list-item';
import { chain } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

/**
 * @deprecated
 */
@Injectable({ providedIn: 'root' })
export class LookupsService {
  constructor(
    private readonly http: HttpClient,
    private readonly url: UrlService,
  ) {}

  getSettings<T>(
    subject: string,
    attribute: string,
    useCache: 'disabled' | 'local' | 'session' = 'disabled',
    failoverArgs?: { subject: string; attribute: string; saveToCache?: 'disabled' | 'local' | 'session' },
  ): Observable<T | undefined> {
    if (['local', 'session'].includes(useCache)) {
      const cachedItem = (useCache === 'local' ? localStorage : sessionStorage).getItem(`setting_${subject}_${attribute}`);
      if (cachedItem !== null) {
        return of(JSON.parse(cachedItem));
      }
    }
    const url = this.url.LOOKUPS_URL.replace('{subject}', subject).replace('{attribute}', attribute);
    return this.http.get<{ value: T }>(url).pipe(
      map(result => result.value),
      tap(value => {
        if (['local', 'session'].includes(useCache)) {
          (useCache === 'local' ? localStorage : sessionStorage).setItem(`setting_${subject}_${attribute}`, JSON.stringify(value));
        }
      }),
      catchError(() => {
        if (!failoverArgs) {
          return of(undefined);
        }
        return this.getSettings<T>(failoverArgs.subject, failoverArgs.attribute, failoverArgs.saveToCache);
      }),
    );
  }

  setSettings<T>(
    subject: string,
    attribute: string,
    value: T,
    useCache: 'disabled' | 'local' | 'session' = 'disabled',
  ): Observable<{ value: T } | undefined> {
    const url = this.url.LOOKUPS_URL.replace('{subject}', subject).replace('{attribute}', attribute);
    if (['local', 'session'].includes(useCache)) {
      (useCache === 'local' ? localStorage : sessionStorage).setItem(`setting_${subject}_${attribute}`, JSON.stringify(value));
    }
    return this.http.post<{ value: T }>(url, { value }).pipe(catchError(() => of(undefined)));
  }

  getSettingsList<T>(subject: string, attribute?: string, useCache: 'disabled' | 'local' | 'session' = 'disabled'): Observable<T[]> {
    if (['local', 'session'].includes(useCache)) {
      const cachedItem = (useCache === 'local' ? localStorage : sessionStorage).getItem(`setting_${subject}_${attribute}`);
      if (cachedItem !== null) {
        return of(JSON.parse(cachedItem));
      }
    }
    const url = this.url.LOOKUPS_LIST_URL.replace('{subject}', subject);
    return this.http
      .get<{ items: Array<{ value: T }> }>(url, attribute ? { params: new HttpParams().set('attribute', attribute) } : undefined)
      .pipe(
        map(result => result.items.map(item => item.value)),
        tap(value => {
          if (['local', 'session'].includes(useCache)) {
            (useCache === 'local' ? localStorage : sessionStorage).setItem(`setting_${subject}_${attribute}`, JSON.stringify(value));
          }
        }),
        catchError(() => {
          return of([]);
        }),
      );
  }

  deleteSettings(subject: string, attribute: string): Observable<Object | undefined> {
    const url = this.url.LOOKUPS_URL.replace('{subject}', subject).replace('{attribute}', attribute);
    return this.http.delete(url).pipe(
      map(result => result),
      catchError(() => of(undefined)),
    );
  }

  getCmmSubmissionFormList(): Observable<SelectListItem[]> {
    return this.getSettings<string[]>('patient_assistance_prior_authorization_request', 'cmm_submission_form').pipe(
      map(value => {
        return chain(value ?? [])
          .sort()
          .map(item => {
            return { name: item, value: item };
          })
          .value();
      }),
    );
  }
}
