/* eslint-disable @typescript-eslint/no-namespace */
import { SelectListItem } from '@shared/models/select-list-item';
import { TenantIdType } from '@shared/models/tenant-id.type';
import { UserRole } from '@shared/models/user-role.model';

export type DataViewItemWithAccess = {
  roleWhitelist?: UserRole[];
  roleBlacklist?: UserRole[];
  forTenantsWhitelist?: TenantIdType[];
  ownerUsername?: string;
};

export namespace Filter {
  export type DataViewFilter = DataViewItemWithAccess & {
    id: string;
    categoryId: string;
    display: string;
    field: string;
    options?: SelectListItem[];
    maxNumOfOptions?: number;
    maxOneGroup?: boolean;
    allowedTypes: AvailableType[];
    disabled?: boolean;
  };

  export type AppliedDataViewFilterSchema = DataViewItemWithAccess & {
    id: string;
    value: unknown;
    type: AvailableType;
    timezone?: string;
  };

  export type AppliedDataViewFilter = DataViewFilter &
    AppliedDataViewFilterSchema & {
      displayValue?: string;
      icon?: string;
      iconText?: string;
      iconTextPostfix?: boolean;
    };
}

export namespace Column {
  export type DataViewColumnSchema = DataViewItemWithAccess & {
    id: string;
    categoryId: string;
    display: string;
    field: string;
    type?: string;
    templateRef?: string;
    exportAsFields?: string[];
    filter?: DataViewItemWithAccess & {
      field?: string;
      display?: string;
      options?: SelectListItem[];
      maxNumOfOptions?: number;
      allowedTypes: AvailableType[];
    };
  };

  export type DataViewColumn = DataViewColumnSchema & {
    categoryId?: string;
    settings?: Record<string, any>;
  };

  export type AppliedDataViewColumnSchema = {
    id: string;
    width?: number | 'auto';
  };

  export type AppliedDataViewColumn = DataViewColumnSchema & {
    width?: number | 'auto';
    sort?: boolean;
    favourite?: boolean;
  };

  export type DataViewColumnGalleryColumn = DataViewColumn & {
    isAdded?: boolean;
    favourite?: boolean;
  };

  export type DataViewColumnGalleryCategory = DataViewItemWithAccess & {
    id: string;
    name: string;
    description?: string;
  };

  export type DataViewColumnGallerySchema = {
    columns: Array<{ id: string } & Partial<DataViewColumnGalleryColumn>>;
    domain: string;
  };

  export type DataViewDomainColumnsSchema = {
    columns: DataViewColumnGalleryColumn[];
    columnCategories: DataViewColumnGalleryCategory[];
  };
}

export const AvailableTypes = <const>[
  'contains',
  'ncontains',
  'in',
  'nin',
  'equal',
  'nequal',
  'range',
  'lt',
  'gt',
  'beginsWith',
  'endsWith',
  'dateRange',
  'datetimeRange',
  'exists',
  'nexists',
  'isOneOf',
  'nisOneOf',
];

export type AvailableType = (typeof AvailableTypes)[number];

export const TypeOptionsMap: Record<AvailableType, string> = {
  beginsWith: 'Begins with',
  contains: 'Contains',
  ncontains: "Doesn't contain",
  dateRange: 'Date range',
  datetimeRange: 'Datetime range',
  endsWith: 'Ends with',
  exists: 'Exists',
  nexists: "Doesn't exist",
  in: 'Is in',
  nin: "Isn't in",
  equal: 'Is',
  nequal: "Isn't",
  range: 'Number range',
  lt: 'Is less than',
  gt: 'Is greater than',
  isOneOf: 'Is one of',
  nisOneOf: "Isn't one of",
};

export namespace View {
  export type MapConfig = {
    displayedInfo: {
      basicInfo: boolean;
      enrollments: boolean;
      scorecard: Record<string, boolean>;
    };
  };

  export type ViewSchema = {
    id: string;
    label: string;
    isPinned?: boolean;
    appliedFilters: Filter.AppliedDataViewFilterSchema[];
    appliedColumns: Column.AppliedDataViewColumnSchema[];
    mapConfig: MapConfig;
  };

  export type View = ViewSchema & {
    appliedColumns: Column.AppliedDataViewColumn[];
    appliedFilters: Filter.AppliedDataViewFilter[];
    unsavedChanges?: boolean;
  };

  export type DefaultView = View & DataViewItemWithAccess;

  export type UserView = View & {
    basedOnViewId: string;
    isCustom?: boolean;
  };
}

export namespace PageConfig {
  export type DataViewPageConfigBase = {
    configVersion?: string;
    pinnedViewIds: string[];
    unpinnedDefaultViewIds: string[];
  };

  export type DataViewPageConfigSchema = DataViewPageConfigBase & {
    views: View.ViewSchema[];
    favouriteColumnIds: string[];
    unpinnedDefaultViewIds: string[];
  };

  export type DataViewPageConfig = DataViewPageConfigBase & {
    views: Array<View.DefaultView | View.UserView>;
    favouriteColumns: Column.DataViewColumn[];
  };
}
