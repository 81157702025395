<div *ngIf="viewMode === 'popover'" class="popover-body" style="width: 100%">
  <div class="modal-header">
    <chitin-select-old
      *ngIf="model.allowedTypes.length > 1"
      [clearable]="false"
      placeholder="type"
      class="flex-grow-1"
      [items]="typeOptions"
      [value]="model.type"
      (valueChange)="onTypeChanged($event)"
      [appendTo]="undefined"
      [autoWidth]="false"
      style="z-index: 1201 !important"
    />
  </div>
  <div class="modal-body d-flex gap-2 align-items-end">
    <!-- <div class="flex-grow-1" style="flex-shrink: 0"> -->
    <div class="flex-grow-1">
      <chitin-range-input
        *ngIf="model.type === 'range'"
        #focusableInputField
        [clearable]="true"
        size="small"
        [value]="$any(model.value)"
        (valueChange)="onValueChanged($event)"
      />
      <chitin-select-old
        *ngIf="model.type === 'in'"
        #focusableInputField
        class="value-select"
        size="sm"
        (valueChange)="onValueChanged($event)"
        placeholder="Select options"
        [disableSelectOnEnter]="true"
        [groupItems]="modelHasGroupedItems"
        [expandSelectedGroupItems]="modelHasGroupedItems"
        [items]="model.options ?? []"
        [multiple]="true"
        [showSelectAll]="true"
        [maxNumOfItems]="model.maxNumOfOptions"
        [displayNumberOfSelected]="true"
        [appendTo]="undefined"
        [alwaysOpen]="true"
        [checkboxView]="true"
        [autoWidth]="true"
        [value]="$any(model.value)"
        [maxOneGroup]="model.maxOneGroup ?? false"
      />
      <chitin-daterange-picker
        *ngIf="['dateRange', 'datetimeRange'].includes(model.type)"
        size="small"
        placeholder="Select Date(s)"
        color="secondary"
        [addDateRangePresets]="['YTD']"
        [hideDateRangePresets]="['This Year']"
        [showLabelContextInfo]="true"
        [showTimezoneSelector]="model.type === 'datetimeRange'"
        [fullwidth]="true"
        [value]="$any(model.value)"
        (valueChange)="onValueChanged($event)"
        [selectedTimezone]="model.timezone"
        (selectedTimezoneChange)="onTimezoneChanged($event)"
      />
      <chitin-chips-input
        *ngIf="model.type === 'isOneOf'"
        #focusableInputField
        size="small"
        [clearable]="false"
        placeholder="value1,value2,..."
        [value]="$any(model.value)"
        [emitValueTrigger]="chipsForceEmitTrigger.asObservable()"
        (emitValueTriggerFinished)="forceEmitFinished($event)"
        (valueChange)="onValueChanged($event)"
      />
      <chitin-form-input
        *ngIf="!['range', 'dateRange', 'datetimeRange', 'exists', 'nexists', 'isOneOf', 'in'].includes(model.type)"
        #focusableInputField
        size="small"
        [clearable]="true"
        placeholder="value"
        [value]="$any(model.value)"
        (valueChange)="onValueChanged($event)"
      />
    </div>
    <chitin-button
      *ngIf="['dateRange', 'datetimeRange'].includes(model.type) && !!model.value"
      size="small"
      color="secondary"
      label="CLEAR"
      (clicked)="clearClicked()"
    />
    <chitin-button size="small" color="secondary" label="OK" (clicked)="okClicked()" />
  </div>
</div>

<div *ngIf="viewMode === 'row'" class="d-flex gap-2">
  <chitin-select-old
    *ngIf="model.allowedTypes.length > 1"
    [clearable]="false"
    placeholder="type"
    [items]="typeOptions"
    [value]="model.type"
    (valueChange)="onTypeChanged($event)"
    [appendTo]="undefined"
    [autoWidth]="false"
    size="sm"
    style="flex-basis: 50%"
  />

  <chitin-range-input
    style="flex-basis: 50%"
    [clearable]="false"
    size="small"
    *ngIf="model.type === 'range'"
    [value]="$any(model.value)"
    (valueChange)="onValueChanged($event)"
    (keyup.enter)="okClicked()"
  />
  <chitin-select-old
    style="flex-basis: 50%"
    *ngIf="model.type === 'in'"
    class="value-select"
    size="sm"
    (valueChange)="onValueChanged($event)"
    placeholder="Select options"
    [groupItems]="modelHasGroupedItems"
    [items]="model.options ?? []"
    [multiple]="true"
    [maxNumOfItems]="model.maxNumOfOptions"
    [displayNumberOfSelected]="true"
    [appendTo]="undefined"
    [checkboxView]="true"
    [autoWidth]="true"
    [value]="$any(model.value)"
  />
  <chitin-daterange-picker
    style="flex-basis: 50%"
    *ngIf="model.type === 'dateRange'"
    size="small"
    placeholder="Select Date(s)"
    color="secondary"
    [addDateRangePresets]="['YTD']"
    [hideDateRangePresets]="['This Year']"
    [showLabelContextInfo]="true"
    [fullwidth]="true"
    [value]="$any(model.value)"
    (valueChange)="onValueChanged($event)"
  />
  <chitin-chips-input
    style="flex-basis: 50%"
    *ngIf="model.type === 'isOneOf'"
    size="small"
    [clearable]="false"
    placeholder="value1,value2,..."
    [value]="$any(model.value)"
    [emitValueTrigger]="chipsForceEmitTrigger.asObservable()"
    (emitValueTriggerFinished)="forceEmitFinished($event)"
    (valueChange)="onValueChanged($event)"
  />
  <chitin-form-input
    style="flex-basis: 50%"
    *ngIf="
      model.type !== 'range' &&
      model.type !== 'dateRange' &&
      model.type !== 'exists' &&
      model.type !== 'nexists' &&
      model.type !== 'isOneOf' &&
      model.type !== 'in'
    "
    size="small"
    [clearable]="false"
    placeholder="value"
    [value]="$any(model.value)"
    (valueChange)="onValueChanged($event)"
  />
</div>
