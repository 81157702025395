<div
  class="form-check"
  [ngClass]="{
    'form-switch': mode === 'switch',
    'form-check-reverse': reverse,
    'form-check-md': size === 'medium',
    'form-check-lg': size === 'large',
  }"
>
  <input
    class="form-check-input"
    type="checkbox"
    [attr.role]="mode === 'switch' ? 'switch' : undefined"
    [attr.id]="inputId"
    [attr.name]="name"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="valueChange.emit($event)"
  />
  @if (label) {
    <label [attr.for]="inputId" class="form-check-label">{{ label }}</label>
  } @else {
    <label [attr.for]="inputId" class="form-check-label"><ng-content /></label>
  }
</div>
