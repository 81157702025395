<nav id="sidebar" class="sidebar d-flex" [ngClass]="{ collapsed: isClosed }">
  <div class="sidebar-content position-relative d-flex flex-column h-100 w-100">
    <a class="sidebar-brand logo" [routerLink]="navLinks[0]?.path">
      <img class="navbar-brand-logo" src="/assets/logo.png" class="img-fluid my-2 px-5" />
    </a>

    <app-loading-spinner *ngIf="loadingCount === 0" [isLoading]="isLoading" />

    <ul class="sidebar-nav nav-links mb-1 p-0 separator-bottom" [ngClass]="{ 'flex-grow-1': !filteredPharmacies.length && !isLoading }">
      <ng-container *ngTemplateOutlet="links; context: { links: navLinks }" />
    </ul>

    <div class="px-4 py-2" *ngIf="pharmacySearchEnabled">
      <input type="text" class="form-control" [(ngModel)]="pharmacySearch" (ngModelChange)="pharmacySearchChanged()" placeholder="Search" />
    </div>

    <div class="flex-grow-1 separator-bottom" *ngIf="isLoading">
      <div *ngFor="let item of [].constructor(loadingCount)" class="placeholder-glow mb-1">
        <span class="placeholder placeholder-lg d-block py-2">L</span>
      </div>
    </div>

    <ul
      class="sidebar-nav mb-0 pb-3 pharmacies flex-grow-1 separator-bottom"
      data-cy="pharmacySelect"
      *ngIf="filteredPharmacies.length > 0"
    >
      <ng-container *ngTemplateOutlet="links; context: { links: filteredPharmacies }" />
    </ul>

    <div class="ms-4" *ngIf="pharmacies && filteredPharmacies && filteredPharmacies.length === 0 && !isLoading">No pharmacies found.</div>

    <ul class="sidebar-nav nav-links mb-0 p-0">
      <ng-container *ngTemplateOutlet="links; context: { links: bottomLinks }" />
    </ul>

    <ng-content />
  </div>
</nav>

<ng-template #links let-links="links">
  <ng-container *ngFor="let link of links">
    <li class="sidebar-item" routerLinkActive="active" *ngIf="link.header !== true">
      <a class="sidebar-link" [routerLink]="link.path" data-cy="navItem">
        <span class="iconWrapper"><i class="{{ link.icon }}"></i></span>
        <span class="align-middle">{{ link.label }}</span>
        <small>
          <span class="badge rounded-pill bg-success ms-1 align-middle badge-new" *ngIf="link.isNew"> New! </span>
        </small>
        <small>
          <span class="badge rounded-pill bg-success ms-1 align-middle badge-new" *ngIf="link.isEarlyAccess"> Early Access </span>
        </small>
        <i *ngIf="link.messageIcon" class="ms-2 {{ link.messageIcon }}" [ngbTooltip]="link.message"></i>
      </a>
      <ng-container *ngTemplateOutlet="sublinks; context: { link }" />
    </li>

    <li class="sidebar-header" *ngIf="link.header === true">{{ link.label }}</li>
  </ng-container>
</ng-template>

<ng-template #sublinks let-link="link">
  <ul class="sidebar-dropdown list-unstyled collapse">
    <li class="sidebar-item" routerLinkActive="active" *ngFor="let sublink of link.sublinks">
      <a class="sidebar-link" [routerLink]="sublink.path" data-cy="navItem">
        <span class="iconWrapper"><i class="{{ sublink.icon }}"></i></span>
        <span class="align-middle">{{ sublink.label }}</span>
        <small><span class="badge rounded-pill bg-success ms-1 align-middle badge-new" *ngIf="sublink.isNew">New!</span></small>
        <small>
          <span class="badge rounded-pill bg-success ms-1 align-middle badge-new" *ngIf="sublink.isEarlyAccess"> Beta </span>
        </small>
        <i *ngIf="sublink.messageIcon" class="ms-2 {{ sublink.messageIcon }}" [ngbTooltip]="sublink.message"></i>
      </a>
    </li>
  </ul>
</ng-template>
