import {
  Component,
  computed,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'chitin-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => ChitinFormInputComponent) }],
})
export class ChitinFormInputComponent<T> implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  inputId?: string;

  @Input()
  name?: string;

  @Input()
  type: 'text' | 'number' | 'tel' | 'email' | 'password' | 'date' | 'file' = 'text';

  @Input()
  isDisabled: boolean = false;

  @Input()
  readonly: boolean = false;

  @Input()
  layout: 'block' | 'inline' = 'block';

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  placeholder?: string;

  @Input()
  autocomplete?: string;

  @Input()
  label?: string;

  @Input()
  prefix?: string;

  @Input()
  suffix?: string;

  @Input()
  showLabelInfo: boolean = false;

  @Input()
  labelInfoTemplate: TemplateRef<any> | null;

  @Input()
  inputInfoText: string | null;

  @Input()
  value: T | null;

  dateValue = computed(() => (this.value ? moment(this.value).toDate() : this.value));

  /**
   * Works only form type 'file'
   */
  @Input()
  acceptFileTypes?: string;

  /**
   * Works only form type 'file'
   */
  @Input()
  multipleFiles?: boolean = false;

  /**
   * Works only form type 'number'
   */
  @Input()
  min?: number;

  /**
   * Works only form type 'number'
   */
  @Input()
  max?: number;

  /**
   * Works only form type 'date'
   */
  @Input()
  maxDate?: Date | string;

  /**
   * Works only form type 'date'
   */
  @Input()
  minDate?: Date | string;

  /**
   * Works only form type 'number'
   */
  @Input()
  stepSize?: number;

  /**
   * Works only form type 'text', 'tel', 'email', 'password'
   */
  @Input()
  minLength?: number;

  /**
   * Works only form type 'text', 'tel', 'email', 'password'
   */
  @Input()
  maxLength?: number;

  /**
   * Works only form type 'text', 'tel', 'email', 'password'
   */
  @Input()
  pattern?: string;

  @Input()
  clearable: boolean = true;

  @Input() emissionDelayInMs?: number;

  @Output()
  valueChange = new EventEmitter<T | null>();

  @Output()
  focused = new EventEmitter<void>();

  @Output()
  blurred = new EventEmitter<void>();

  @ViewChild('datePicker')
  datePicker: MatDatepicker<any>;

  private valueDebouncer = new Subject<T | null>();
  private componentDestroyed = new Subject<void>();
  private onChange?: Function;
  private onTouched?: Function;
  @ViewChild('inputFieldToFocus')
  private inputFieldToFocus: ElementRef;

  public ngOnInit(): void {
    if (this.isEmissionDelay()) {
      this.debounceValueEmissions();
    }
  }

  public ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(value: any) {
    this.value = value;
  }

  focus() {
    setTimeout(() => {
      this.inputFieldToFocus.nativeElement.focus();
    });
  }

  blur() {
    setTimeout(() => {
      this.inputFieldToFocus.nativeElement.blur();
    });
  }

  onBlur() {
    this.blurred.next();
    this.onTouched ? this.onTouched() : null;
  }

  onFocus() {
    this.datePicker.open();
    this.focused.next();
  }

  public emitChange(value: T | null): void {
    if (this.isEmissionDelay()) {
      this.valueDebouncer.next(value);
    } else {
      this.changeValue(value);
    }
  }

  fileChange(event: any) {
    if (event.target.files) {
      this.emitChange(event.target.files);
    }
  }

  public clearAndEmit(): void {
    this.emitChange(null);
  }

  private debounceValueEmissions(): void {
    if (!this.emissionDelayInMs) return;
    this.valueDebouncer.pipe(debounceTime(this.emissionDelayInMs), takeUntil(this.componentDestroyed)).subscribe((value: T | null) => {
      this.changeValue(value);
    });
  }

  private isEmissionDelay(): boolean {
    return !!(this.emissionDelayInMs ?? 0);
  }

  private changeValue(value: T | null) {
    this.value = value;
    this.valueChange.next(value);
    this.onChange ? this.onChange(value) : null;
  }
}
