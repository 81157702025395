import { earlyPaymentProductFragment } from '@services/graphql/pharmacy/early-payment-product.fragment';
import { pharmacyPaymentStatementFragment } from '@services/graphql/pharmacy/pharmacy-statement.fragment';
import gql from 'graphql-tag';

export const GetPaymentStatementDetailQuery = gql`
  ${earlyPaymentProductFragment}
  ${pharmacyPaymentStatementFragment}
  query GetPaymentStatementDetail($tenantId: String!, $ncpdp: String!, $periodEnd: AWSDateTime!) {
    tenant(id: $tenantId) {
      pharmacy(ncpdp: $ncpdp) {
        name
        npi
        paymentStatement(periodEnd: $periodEnd) {
          ...PharmacyPaymentStatement
          earlyPayment {
            acceptedAt
            declinedAt
            dueDate
            requestId
            requestedAt
            status
            product {
              ...EarlyPaymentProduct
            }
            products {
              ...EarlyPaymentProduct
            }
          }
        }
      }
    }
  }
`;
