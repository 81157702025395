@if (viewMode === 'chips') {
  <div class="d-flex gap-1" style="margin-top: 3px">
    @for (filter of appliedFilters; track $index) {
      <div
        class="badge filter-badge text-black d-flex align-items-center filterBadge"
        style="background-color: white; font-size: 0.85rem"
        [ngbPopover]="addFilterPopover"
        placement="bottom-left"
        #filterPopover="ngbPopover"
        (click)="openPopover(filter)"
        [autoClose]="false"
        popoverClass="filterPopover"
      >
        <span class="me-1" data-cy="pharmacyFilter">{{ filter.display }}</span>

        <chitin-icon class="me-1" style="font-size: 0.7rem" *ngIf="filter.icon" [icon]="filter.icon" />
        <span class="me-1 iconTextSmall" *ngIf="filter.iconText && !filter.iconTextPostfix">{{ filter.iconText }}</span>

        <span>{{ filter.displayValue ?? filter.value }}</span>

        <span *ngIf="filter.iconText && filter.iconTextPostfix">{{ filter.iconText }}</span>

        <a class="ms-1" data-cy="removeFilter" (click)="removeFilter($index)">
          <chitin-icon icon="fa-solid fa-xmark" />
        </a>
        <ng-template #addFilterPopover>
          <div class="filterPopoverContainer">
            <app-data-view-add-filter
              *ngIf="activeFilter"
              [model]="activeFilter"
              [activePopover]="filterPopover"
              (filterChanged)="filterEdited($event)"
            />
          </div>
        </ng-template>
      </div>
    }
  </div>
  <!-- <div class="d-none flex-shrink-0 gap-2">
    <chitin-button
      [disabled]="isLoadingOptions"
      (clicked)="addFilter()"
      size="small"
      color="secondary"
      icon="fa-solid fa-plus"
      label="Add filter"
    />
    <chitin-button
      [disabled]="isLoadingOptions"
      *ngIf="appliedFilters.length > 0"
      (clicked)="clearAllClicked()"
      size="small"
      color="secondary"
      label="Clear all"
      data-cy="clearBtn"
    />
  </div> -->
} @else {
  <div>Not supported</div>
}
